@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-main: hsla(26, 60%, 67%, 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-hollow {
  @apply border border-main px-6 py-2 hover:bg-main hover:text-secondary-dark hover:border-secondary-dark hover:font-bold;
}

.btn-hollow-sm {
  @apply border-0.5 border-secondary-light px-4 py-1 hover:bg-secondary-light hover:text-secondary-dark hover:font-bold;
}
