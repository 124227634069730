.billingForm label {
  @apply flex flex-col gap-1;
}

.billingForm input {
  @apply input input-sm w-full p-2 border border-gray-300 rounded bg-white;
}

.billingForm label:first-child {
  @apply flex flex-row items-center gap-0 mb-6;
}

.billingForm label:first-child input {
  @apply checkbox;
}
