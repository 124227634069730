.form-txt {
  position: relative;
  font-family: sans-serif;
  /* background-color: var(--dark-blue); */
}

.form-txt:hover input {
  border-width: 2px;
  /* border-color: var(--light-skin); */
}

/* static */
.form-txt p {
  font-family: inherit;
  /* color: white; */
  line-height: 30px;
  padding: 0 5px;
  display: inline-block;
}

/* dynamic: style on top of static */
.form-txt.dynamic p {
  position: absolute;
  transition: all 0.15s linear;
  margin-left: 10px;
}

/* static */
.form-txt img {
  height: 15px;
  position: absolute;
  left: 10px;
  /* top: 37px; */
  top: 65%;
}

/* dynamic */
.form-txt.dynamic img {
  right: 10px;
}

.form-txt input {
  font-family: inherit;
  background-color: white;
  border: 0.5px solid #6e7179;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  /* color: white; */
  padding-left: 30px;
}

.form-txt:hover {
  cursor: text;
}

.form-txt input:focus {
  border-width: 2px;
  /* border: 1px solid var(--light-skin); */
  outline: none;
}

.form-txt.dynamic p:has(~ input:focus) {
  transform: translateY(-15px);
  background-color: inherit;
  font-size: 0.7em;
}
