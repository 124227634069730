.form-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}
.form-checkbox:hover {
  cursor: pointer;
}

.form-checkbox input {
  display: none;
  /* accent-color: var(--light-skin); */
}

.form-checkbox svg path {
  fill: var(--light-skin);
}

.form-checkbox svg {
  width: 15px;
  height: 15px;
}

.form-checkbox p {
  font-family: sans-serif;
  color: var(--light-skin);
}
