.banner-cookie-container {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-height: calc(100vh - 40px);
  width: 440px;
  z-index: 100000000000000000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: black;
  font-weight: 400;
  overflow: scroll;
}

.banner-cookie-container p {
  line-height: 1.2;
}

.banner-cookie-container a {
  color: inherit;
}

.banner-cookie-container h2 {
  margin-bottom: 20px;
  font-weight: 600;
}

.banner-cookie-container h3 {
  font-weight: 500;
}

.banner-cookie-container .buttons {
  display: flex;
  margin: 20px 0 0;
  align-items: center;
}

.banner-cookie-container .buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 5px;
}

.banner-cookie-container .buttons button > p > span {
  font-size: 14px;
  font-weight: 700;
}

.banner-cookie-container .buttons button:first-of-type {
  background-color: #0f1720;
  color: white;
}

.banner-cookie-container .buttons button:last-of-type {
  border: none;
  padding: 2px 0px;
  border-bottom: 1.8px black solid;
  border-radius: 0px;
  background-color: transparent;
}

.banner-cookie-container .buttons button:hover {
  cursor: pointer;
}

.banner-cookie-container .manage-cookies-container {
  height: 0;
  display: none;
}

.banner-cookie-container .manage-cookies-container.open {
  height: auto;
  display: block;
  transition: height 2s ease-in-out;
}

.banner-cookie-container .manage-cookies-container ul.manage-cookies {
  margin: 30px 0;
  list-style: none;
}

.banner-cookie-container .manage-cookies-container ul.manage-cookies li {
  margin: 20px 0;
  padding-top: 20px;
  border-top: 0.5px solid #2c384f29;
}

.banner-cookie-container .manage-cookies-container ul.manage-cookies li > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}

.banner-cookie-container .manage-cookies-container button.save {
  width: 100%;
  background-color: #0f1720;
  padding: 10px;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  border: none;
}

.banner-cookie-container .manage-cookies-container button.save:hover {
  cursor: pointer;
}

/* Hide the scrollbar for webkit browsers (Chrome, Safari, etc.) */
.banner-cookie-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
  display: none;
}

/* Optional: Style the track (the area behind the thumb) */
.banner-cookie-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Optional: Style the scrollbar thumb */
.banner-cookie-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0.25em;
}

/* Optional: Style the scrollbar on hover */
.banner-cookie-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.toggle-custom .layer {
  width: 58px;
  height: 32px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.066);
  position: relative;
}

.toggle-custom .layer:hover {
  cursor: pointer;
}

.toggle-custom .layer.no {
}

.toggle-custom .layer.yes {
  background-color: #0f1720;
  opacity: 1;
}

.toggle-custom .layer.yes .knob {
  right: 6px;
  left: unset;
}

.toggle-custom .layer .knob {
  position: absolute;
  height: 22px;
  width: 22px;
  left: 5px;
  right: unset;
  top: 5px;
  border-radius: 50px;
  background-color: white;
}

@media screen and (max-width: 430px) {
  .banner-cookie-container {
    width: 100vw;
    left: 0;
    bottom: 0px;
    max-height: calc(100vh - 150px);
  }
}
